import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { map } from 'rxjs/operators';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'fl-authentication-layout',
  styleUrls: ['./authentication-layout.component.scss'],
  templateUrl: './authentication-layout.component.html'
})
export class AuthenticationLayoutComponent implements OnInit {
  showLoginLink$ = this.route.data.pipe(map((data) => !!data.showLoginLink));

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {}
}
