import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { AuthenticationLayoutComponent } from './authentication-layout.component';

@NgModule({
  declarations: [AuthenticationLayoutComponent],
  exports: [AuthenticationLayoutComponent, RouterModule],
  imports: [CommonModule, InlineSVGModule, RouterModule]
})
export class AuthenticationLayoutModule {}
