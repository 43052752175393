<header>
  <h1>
    <a href="/" inlineSVG="/assets/flow_white.svg">
      Flow
    </a>
  </h1>
  <h2>Your OS for Alternative Assets</h2>
</header>


<main>
  <a class="auth-login-link" [routerLink]="['/login']" *ngIf="showLoginLink$ | async">Back to login</a>
  <router-outlet></router-outlet>
</main>
