import { CommonModule } from '@angular/common';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { AuthenticationLayoutModule } from '@app/layout/authentication-layout/authentication-layout.module';
import { FlowMaterialModule } from '@app/flow-material.module';
import { MessageBoxModule } from '@component/message-box';

import { ResetPasswordComponent } from './reset-password.component';
import { ResetPasswordRoutingModule } from './reset-password-routing.module';

@NgModule({
  declarations: [ResetPasswordComponent],
  imports: [
    AuthenticationLayoutModule,
    CommonModule,
    FlowMaterialModule,
    MatSnackBarModule,
    MessageBoxModule,
    ReactiveFormsModule,
    ResetPasswordRoutingModule,
  ]
})
export class ResetPasswordModule {}
