<ng-container *ngIf="!loading && ({ peek: peek$ | async, peekError: peekError$ | async }) as vm">
  <h2 class="auth-title">Reset your password</h2>
  <p *ngIf="!vm.peekError" class="auth-intro">To continue please create a strong password below:</p>
  <form [formGroup]="resetPasswordForm" class="auth-form" (ngSubmit)="onSubmit()">
    <div class="with-password-score">
      <mat-form-field *ngIf="!vm.peekError" appearance="outline" [floatLabel]="'always'" [hideRequiredMarker]="true">
        <mat-label>Password</mat-label>

        <input matInput
          autocapitalize="off"
          autocorrect="off"
          autofocus
          data-testid="password-input"
          formControlName="password"
          placeholder="Enter password"
          required
          spellcheck="false"
          type="password"
        />

        <div *ngIf="!resetPasswordForm.pristine" class="password-score password-score-{{ passwordScore }}">
          <span class="password-score-badge weak-password">Weak</span>
          <span class="password-score-badge fair-password">Fair</span>
          <span class="password-score-badge good-password">Good</span>
          <span class="password-score-badge strong-password">Strong</span>
        </div>

        <mat-hint>Your password should have at least 8 characters, include uppercase and lowercase letters, and a number or symbol.</mat-hint>
      </mat-form-field>
    </div>
    <mat-form-field *ngIf="!vm.peekError" class="confirm-password-input" appearance="outline" [floatLabel]="'always'" [hideRequiredMarker]="true">
      <mat-label>Confirm Password</mat-label>
      <input matInput data-testid="confirm-password-input" type="password" placeholder="Confirm password"
              autocorrect="off" formControlName="confirmPassword" autocapitalize="off" spellcheck="false" required />
      <mat-hint>Both passwords must match</mat-hint>
    </mat-form-field>
    <fl-message-box *ngIf="resetPasswordForm.errors?.notSame && !resetPasswordForm.get('confirmPassword').pristine" class="confirm-password-message-box" [type]="messageBoxType.Alert">
      <p>Please check you have entered the same new password in both fields above.</p>
    </fl-message-box>
    <fl-message-box *ngIf="vm.peekError" [type]="messageBoxType.Warning">
      <p>It seems you are using an expired link.</p>
      <p><a [href]="forgetPasswordLink">Click here</a> to request a new password reset link.</p>
    </fl-message-box>
    <button *ngIf="!vm.peekError" color="primary" class="auth-submit-button" type="submit"
            [disabled]="resetPasswordForm.invalid || disabled">
      Reset Password
    </button>
  </form>
</ng-container>
